<template>
    <div>
        <b-card>
            <app-view-table :title="trans('cao',182)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :filterOptions="cao_visible_status" :isFilterOptions="true" :customColumns="customColumns" :items="items"  :isSearch="true" :fields="fields" v-on:onRowClicked="getProfile" class="table-selectable">
                
                <template v-slot:webform_visible="cell">
                    <!--TODO LKM-->
                    {{(cell.data.item.webform_visible == 1 ? 'Ja' : 'Nee')}}
                </template>

                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="changeName(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                </template>    
            </app-view-table>
        </b-card>
        <settings-section-change v-if="mSectionEdit.show" :show.sync="mSectionEdit.show" :result.sync="mSectionEdit.result" :args="mSectionEdit.args" :return.sync="items" />
    </div>
</template>
<script>


import axios from "@axios";
import SettingsSectionChange from './SettingsSectionChange.vue'

export default {
    components:{
        SettingsSectionChange
    },
    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields: [
                { key: "name", label: this.trans('full-name',3), thStyle: { "min-width": "90px", width: "100%" }, visible: true},
                { key: "name_short", label: this.trans('short-name',182), thStyle: { "min-width": "130px", "max-width": "400px" }, visible: true},
                { key: "webform_visible", label: "Webform visible", thStyle: { "min-width": "90px", "max-width": "900px" }, visible: true}
            ],
            items: [],

            customColumns: ["webform_visible"],

            cao_visible_status: [
                { value: 1, name: this.trans('settings-visible',167) },
                { value: 2, name: this.trans('settings-not-visible',167) },
            ],

            mSectionEdit: { show: false, args: {
                    section_data:{
                        id: null,
                        name: null,
                        name_short: null,
                        webform_visible: null
                    },
                    filters: null,
                }, result: 0, return: null },
        };
    },


    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/users/getSectionList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
                
        },

        changeName(item) {
            this.mSectionEdit.args.section_data.id = item.id;
            this.mSectionEdit.args.section_data.name = item.name
            this.mSectionEdit.args.section_data.name_short = item.name_short;
            this.mSectionEdit.args.section_data.webform_visible = item.webform_visible;
            this.mSectionEdit.args.filters = this.filters;
            this.mSectionEdit.show = true;
            
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.list_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
        },

        getProfile: function(selectedRows) {
            if(selectedRows.length != 0){
                this.$router.push({ name: "sections-profile", params: { id_cao_data_main: selectedRows.id } });
            }
        },
    }
 
};
</script>