<template>
    <b-modal ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('settings-change-cao',167)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('full-name',3)">
                    <app-input v-model="cArgs.section_data.name" validatorName="Naam" validatorRules="required" mask="lettersE5-40" />
                </app-row-left-label>

                <app-row-left-label :label="trans('short-name',182)">
                    <app-input v-model="cArgs.section_data.name_short" validatorName="Korte naam" mask="lettersE6-15"/>
                </app-row-left-label>

                <!--TODO LKM-->
                <app-row-left-label label="Webform visible" >
                    <app-check-box id="ch_webform_visible" v-model="cArgs.section_data.webform_visible" class="custom-control-primary" value="1" unchecked-value="0" size="lg"/>
                </app-row-left-label>

            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="changeSection" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
       changeSection() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;
                    axios
                        .post("settings/users/changeSectionName", {
                            section_data: JSON.stringify(this.cArgs.section_data),
                            filters: JSON.stringify(this.cArgs.filters)
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn =  res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading = false;
                        });
                    }
            })
        },
        
        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>

<style scoped></style>
